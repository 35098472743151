import { sendErrorToSentry } from '@/thirdParties/sentry'
import React, { useEffect } from 'react'

const setHasSeen = () => {
  try {
    localStorage.setItem('hasSeenMigrationModale', 'true')
  } catch (e) {
    // Prevent crash if storage is disabled.
    sendErrorToSentry(new Error(`Writing to localStorage failed in MigrationModal.`), {
      level: 'error',
      fingerprint: ['unknown-step'],
    })
  }
}

export const MigrationModale: React.FC = () => {
  useEffect(setHasSeen, [])

  return null
}
